import { inject, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'src/environments/environment';
import { ContactEntryDTO } from '../types/contact.interface';
import { NewsletterDTO } from '../types/newsletter.interface';
import { Navbar } from '../types/navbar.interface';
import { Event } from '../types/event.interface';
import { Footer } from '../types/footer.interface';
import { News, NewsEntry } from '../types/news.interface';
import { ReadonlyWeather } from '../types/weather.interface';
import { Page } from '../types/page.interface';
import { NewsInput } from '../types/input.types';
import { injectQuery } from '@ngneat/query';
import { NewsletterConfirmationDTO, EventDTO } from '../shared/dist/types';

@Injectable({
  providedIn: 'root'
})
export class ApiService {
  private readonly apiUrl = environment.apiUrl;
  #query = injectQuery();
  private readonly http = inject(HttpClient);

  getPageBySlug(slug: string, languageCode: string): Observable<Page> {
    const query = `
      query {
        pages(filters: { slug: { eq: "${slug}" } }, locale: "${languageCode}") {
          data {
            attributes {
              components {
                component: __typename
                ... on ComponentMainAccordionList {
                  accordions {
                    title
                    subtitle
                    paragraphs {
                      paragraphEntries {
                        content
                      }
                    }
                  }
                }
                ... on ComponentMainStadiumOverview {
                  image {
                    data {
                      attributes {
                        alternativeText
                        formats
                        url
                      }
                    }
                  }
                }
                ... on ComponentMainTextImage {
                  image {
                    data {
                      attributes {
                        alternativeText
                        formats
                        url
                      }
                    }
                  }
                  content
                }
                ... on ComponentMainMultiLine {
                  content
                }
                ... on ComponentMainTimelineTable {
                  timelines {
                    date
                    timelineEntries {
                      time
                      title
                      text
                    }
                  }
                }
                ... on ComponentMainScrollSpy {
                  scrollSpyEntries {
                    title {
                      text
                      size
                      align
                    }
                    paragraphs {
                      paragraphEntries {
                        content
                      }
                    }
                    linkItem {
                      text
                      link
                    }
                  }
                }
                ... on ComponentMainImageWithFiveCaptions {
                  image {
                    data {
                      attributes {
                        alternativeText
                        formats
                        url
                      }
                    }
                  }
                  captions {
                    text
                    link
                  }
                }
                ... on ComponentMainArrivalSection {
                  arrivalTitle {
                    text
                    size
                    align
                  }
                  paragraphs {
                    paragraphEntries {
                      content
                    }
                  }
                  linkItem {
                    text
                    link
                  }
                  lottieIsLeft
                  image {
                    data {
                      attributes {
                        alternativeText
                        formats
                        url
                      }
                    }
                  }
                }
                ... on ComponentMainTicketList {
                  categories {
                    category
                    tickets(pagination: {pageSize:40}) {
                      price
                      linkItem {
                        text
                        link
                      }
                    }
                  }
                  stadiumOverview {
                    image {
                      data {
                        attributes {
                          alternativeText
                          formats
                          url
                        }
                      }
                    }
                  }
                }
                ... on ComponentMainTicket {
                  price
                  linkItem {
                    text
                    link
                  }
                }
                ... on ComponentMainHeadline {
                  text
                  size
                  align
                }
                ... on ComponentMainNews {
                  isLimited
                  limit
                }
                ... on ComponentMainContact {
                  successText
                  failureText
                }
                ... on ComponentMainTeam {
                  teamGroups {
                    role
                    teamGroupEntries {
                      position
                      teamGroupEntryNames {
                        name
                      }
                    }
                  }
                }
                ... on ComponentMainNewsletter {
                  newsletterTitle: title
                }
                ... on ComponentMainGallery {
                  pageSize
                }
                ... on ComponentMainParagraphs {
                  paragraphEntries {
                    content
                  }
                }
                ... on ComponentMainWeather {
                  weatherTitle: title
                }
                ... on ComponentMainLonginesTimer {
                  date
                }
                ... on ComponentMainPresentedBy {
                  presentedByTitle: title
                  leftImage {
                    image {
                      data {
                        attributes {
                          alternativeText
                          formats
                          url
                        }
                      }
                    }
                    link
                  }
                  rightImage {
                    image {
                      data {
                        attributes {
                          alternativeText
                          formats
                          url
                        }
                      }
                    }
                    link
                  }
                  organizer {
                    image {
                      data {
                        attributes {
                          alternativeText
                          formats
                          url
                        }
                      }
                    }
                    link
                  }
                  host {
                    image {
                      data {
                        attributes {
                          alternativeText
                          formats
                          url
                        }
                      }
                    }
                    link
                  }
                }
                ... on ComponentMainPartner {
                  logos {
                    image {
                      data {
                        attributes {
                          alternativeText
                          formats
                          url
                        }
                      }
                    }
                    link
                  }
                }
                ... on ComponentMainSlider {
                  images {
                    data {
                      attributes {
                        alternativeText
                        formats
                        url
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    `;
    return this.http.post<Page>(`${this.apiUrl}/graphql`, { query });
  }

  getLatestNews(languageCode: string, newsInput: NewsInput | undefined) {
    return this.#query({
      queryKey: ['latestNews', languageCode, newsInput?.limit] as const,
      queryFn: () => {
        const query = `
          query {
            newsEntries(pagination: { limit: ${newsInput?.isLimited ? newsInput?.limit : 100} }, sort: "date:desc", locale: "${languageCode}") {
              data {
                attributes {
                  title
                  slug
                  date
                  textImage {
                    image {
                      data {
                        attributes {
                          url
                          formats
                          alternativeText
                        }
                      }
                    }
                    content
                  }
                }
              }
            }
          }
        `;
        return this.http.post<News>(`${this.apiUrl}/graphql`, { query });
      }
    });
  }

  getNewsEntryBySlug(slug: string, languageCode: string) {
    return this.#query({
      queryKey: ['newsEntry', languageCode, slug] as const,
      queryFn: () => {
        const query = `
          query {
            newsEntries(filters: { slug: { eq: "${slug}" } }, locale: "${languageCode}") {
              data {
                attributes {
                  title
                  date
                  textImage {
                    image {
                      data {
                        attributes {
                          url
                          formats
                          alternativeText
                        }
                      }
                    }
                    content
                  }
                }
              }
            }
          }
        `
        return this.http.post<NewsEntry>(`${this.apiUrl}/graphql`, { query });
      }
    })
  }

  getEventByUUID(uuid: string) {
    return this.#query({
      queryKey: ['uuid', uuid] as const,
      queryFn: () => {
        const query = `
        query {
          eventByUUID(uuid: "${uuid}") {
            data {
              id
              attributes {
                title
                eventStartDates {
                  data {
                    id
                    attributes {
                      date
                      title
                    }
                  }
                }
                sendTo
              }
            }
          }
        }
      `;
        return this.http.post<Event>(`${this.apiUrl}/graphql`, { query });
      }
    });
  }

  getNavbar(languageCode: string) {
    const query = `
          query {
            navbar(locale: "${languageCode}") {
              data {
                attributes {
                  navbarItems {
                    linkItem {
                      text
                      link
                    }
                    subLinkItems {
                      text
                      link
                    }
                  } 
                }
              }
            }
          }
        `;
    return this.http.post<Navbar>(`${this.apiUrl}/graphql`, { query });
  }

  getFooter(languageCode: string): Observable<Footer> {
    const query = `
      query {
        footer(locale: "${languageCode}") {
          data {
            attributes {
              footerItems {
                text
                link
              }
            }
          }
        }
      }
    `;
    return this.http.post<Footer>(`${this.apiUrl}/graphql`, { query });
  }

  createEventRegistration(data: EventDTO): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/event-registrations`, data);
  }

  createNewsletterEntry(data: NewsletterDTO): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/newsletters`, data);
  }

  createContactRequest(data: ContactEntryDTO): Observable<any> {
    return this.http.post(`${this.apiUrl}/api/contact-requests`, data);
  }

  getWeather(languageCode: string) {
    return this.#query({
      queryKey: ['languageCode', languageCode] as const,
      queryFn: () => this.http.get<ReadonlyWeather>(`${this.apiUrl}/uploads/weather_${languageCode}.json`)
    })
  }

  confirmEmail(data: NewsletterConfirmationDTO): Observable<any> {
    return this.http.put(`${this.apiUrl}/api/newsletters/confirm`, data);
  }

  unsubscribeEmail(dto: NewsletterConfirmationDTO): Observable<any> {
    return this.http.delete(`${this.apiUrl}/api/newsletters/unsubscribe/${dto.data.uuid}/${dto.data.email}`);
  }
}

